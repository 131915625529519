import { Box, Skeleton, SkeletonText, Grid, GridItem } from "@chakra-ui/react";
export function MonthlyFeatureLoading({
  manifest,
}: Readonly<Record<string, any>>): JSX.Element {
  const primaryColor = manifest["stylesheet"]["colors"]["primary"];

  return (
    <Box
      display="grid"
      height="600px"
      alignContent={"center"}
      justifyContent={"center"}
      alignItems={"center"}
      bg={primaryColor}
    >
      <Box
        display="grid"
        alignContent={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        mt="15px"
      >
        <SkeletonText
          width="450px"
          noOfLines={1}
          spacing="4"
          skeletonHeight="7"
          mb="25px"
        />
      </Box>
      <Grid
        display="grid"
        mt="15px"
        templateColumns="repeat(2, 1fr)"
        gap={4}
      >
        <GridItem
          display="grid"
          mt="15px"
        >
          <Skeleton
            width="350px"
            height="500px"
            ml="5px"
          />
        </GridItem>
        <GridItem
          display="grid"
          mt="15px"
        >
          <Skeleton
            width="350px"
            height="500px"
            ml="5px"
          />
        </GridItem>
      </Grid>
      <Box
        display="grid"
        alignContent={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        mt="15px"
      >
        <Skeleton
          width="150px"
          height="50px"
        />
      </Box>
    </Box>
  );
}
