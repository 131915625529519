import {
  useMediaQuery,
  Box,
  VStack,
  HStack,
  Text,
  Image,
} from "@chakra-ui/react";
import styled from "styled-components/macro";

export const Icon = styled(Image)`
  height: 140px;
  width: 140px;
  margin: 30px;

  @media (max-width: 768px) {
    height: 70px;
    width: 70px;
    margin: 15px;
  }
  @media (max-width: 480px) {
    height: 45px;
    width: 45px;
    margin: 10px;
  }
  @media (max-width: 300px) {
    height: 30px;
    width: 30px;
    margin: 8px;
  }
`;

export default function ErrorPageDetail(): JSX.Element {
  const s3 =
    "https://ocv-web-icons.s3.us-east-2.amazonaws.com/pageNotFound.svg";

  const [isLessThan300px] = useMediaQuery("(max-width: 300px)");

  return (
    <Box
      as="section"
      width="100%"
      height="60dvh"
      p="2rem 0"
      display="flex"
      justifyContent="center"
      bg="white"
    >
      <Box
        width={[
          "200px", // <= 300px
          "280px", // > 300px
          "600px", // > 480px
          "800px", // > 768px
          "900px", // > 992px
        ]}
      >
        <HStack spacing="1rem">
          <Box
            width={
              isLessThan300px ? "50px" : ["75px", "100px", "200px", "300px"]
            }
          >
            <Icon
              src={s3}
              alt="Caution Symbol"
            />
          </Box>
          <VStack>
            <Box
              width={[
                "150px", // <= 300px
                "200px", // > 300px
                "400px", // > 480px
                "600px", // > 768px
                "700px", // > 992px
              ]}
            >
              <Text
                m="4"
                color="black"
                fontFamily="Fjalla One"
                fontSize={["20px", "24px", "48px", "64px"]}
              >
                Oops!
              </Text>
              <Text
                m="4"
                color="black"
                fontFamily="Roboto, sans-serif"
                fontSize={["14px", "14px", "28px", "38px"]}
              >
                We can’t seem to find the page you’re looking for. Try
                refreshing your browser or going back to the previous page.
              </Text>
            </Box>
          </VStack>
        </HStack>
      </Box>
    </Box>
  );
}
