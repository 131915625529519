// ErrorPage will occur when OCVManifest.buildRoutes(data) is not able to find the route.
import { Navbar } from "../../views/Navbar/Navbar";
import Footer from "../../views/Footer/Footer";
import ErrorPageDetail from "./ErrorPageDetail";
import ReactGA from "react-ga4";
import { useEffect } from "react";

export default function ErrorPage({
  manifest,
}: Readonly<Record<string, any>>): JSX.Element {
  useEffect(() => {
    // Track page view when the error page is rendered
    const currentPath = window.location.pathname + window.location.search;
    ReactGA.send({
      hitType: "pageview",
      page: "/error",
      title: `Error- ${currentPath}`,
    });
  }, []);

  // Check if manifest is null or undefined
  if (!manifest) {
    // Return null or some JSX to handle this case
    return <ErrorPageDetail />;
  }

  return (
    <>
      <Navbar manifestData={manifest} />
      <ErrorPageDetail />
      <Footer manifestData={manifest} />
    </>
  );
}
