import {
  Box,
  VStack,
  Grid,
  GridItem,
  Skeleton,
  SkeletonText,
} from "@chakra-ui/react";
export function AtAGlanceLoading({
  manifest,
}: Readonly<Record<string, any>>): JSX.Element {
  const primaryColor = manifest["stylesheet"]["colors"]["primary"];

  return (
    <Box
      display="grid"
      height="500px"
      alignContent={"center"}
      justifyContent={"center"}
      alignItems={"center"}
      bg={primaryColor}
    >
      <Box
        display="grid"
        justifyContent={"center"}
      >
        <SkeletonText
          width="300px"
          noOfLines={1}
          spacing="4"
          skeletonHeight="5"
        />
      </Box>
      <Grid
        display="grid"
        mt="15px"
        templateColumns="repeat(2, 1fr)"
        gap={4}
      >
        <GridItem
          display="grid"
          mt="15px"
        >
          <VStack>
            <Skeleton
              width="450px"
              height="125px"
            />
            <Skeleton
              width="450px"
              height="125px"
            />
            <Skeleton
              width="450px"
              height="125px"
            />
          </VStack>
        </GridItem>
        <GridItem
          display="grid"
          mt="15px"
        >
          <SkeletonText
            width="450px"
            noOfLines={5}
            spacing="4"
            skeletonHeight="5"
          />
        </GridItem>
      </Grid>
    </Box>
  );
}
