/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IconButton, Tooltip, Box } from "@chakra-ui/react";
import { TriangleUpIcon } from "@chakra-ui/icons";

export default function ScrollToTop() {
  function handleClick() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <Box
      id="ScrollToTop"
      position="absolute"
      right={["1rem", "2rem"]}
      zIndex={3}
      my="-10"
    >
      <Tooltip
        id="ScrollToTopTooltip"
        label="Click to scroll back to top"
        placement="top"
      >
        <IconButton
          aria-label="Scroll to Top"
          variant="solid"
          borderColor="black"
          borderWidth="1px"
          bg="white"
          onClick={() => {
            handleClick();
          }}
          icon={
            <TriangleUpIcon
              aria-hidden="true"
              role="presentation"
            />
          }
        />
      </Tooltip>
    </Box>
  );
}
