import { HStack, Box, SkeletonText, Skeleton } from "@chakra-ui/react";
export function BlogGridWidgetLoading({
  manifest,
}: Readonly<Record<string, any>>): JSX.Element {
  const primaryColor = manifest?.["stylesheet"]["colors"]["primary"];

  return (
    <Box
      display="grid"
      height="500px"
      alignContent={"center"}
      justifyContent={"center"}
      alignItems={"center"}
      bg={primaryColor}
    >
      <Box
        display="grid"
        justifyContent={"center"}
        mb="15px"
      >
        <SkeletonText
          width="300px"
          noOfLines={1}
          spacing="4"
          skeletonHeight="5"
        />
      </Box>
      <Box
        mt="25px"
        display="grid"
        justifyContent={"center"}
      >
        <HStack>
          <Skeleton
            width="200px"
            height="150px"
          />
          <Skeleton
            width="200px"
            height="150px"
            mr="5px"
            ml="5px"
          />
          <Skeleton
            width="200px"
            height="150px"
          />
        </HStack>
      </Box>
    </Box>
  );
}
