import { FooterLoading } from "./FooterLoading";
import { NavbarLoading } from "./NavbarLoading";
import { FeatureBarLoading } from "./FeatureBarLoading";
import { AtAGlanceLoading } from "./AtAGlanceLoading";
import { SliderLoading } from "./SliderLoading";
import { WelcomeLoading } from "./WelcomeLoading";
import { SocialBarLoading } from "./SocialBarLoading";
import { LabelLoading } from "./LabelLoading";
import { PossibilityBoxLoading } from "./PossibilityBoxLoading";
import { NewsDigestLoading } from "./NewsDigestLoading";
import { TwoColumnButtonLoading } from "./TwoColumnButtonLoading";
import { TwoColumnViewLoading } from "./TwoColumnViewLoading";
import { TwoColumnVideoLoading } from "./TwoColumnVideoLoading";
import { SocialMediaLoading } from "./SocialMediaLoading";
import { ContactCardLoading } from "./ContactCardLoading";
import { BlogCardLoading } from "./BlogCardLoading";
import { BlogCarouselLoading } from "./BlogCarouselLoading";
import { BlogFAQLoading } from "./BlogFAQLoading";
import { BlogGridWidgetLoading } from "./BlogGridWidgetLoading";
import { VideoPlayerLoading } from "./VideoPlayerLoading";
import { MonthlyFeatureLoading } from "./MonthlyFeatureLoading";

export function HomePageLoading({
  manifest,
}: Readonly<Record<string, any>>): JSX.Element {
  const homeOrder = manifest.homeOrder;
  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1.25rem 0",
        justifyContent: "center",
      }}
    >
      <NavbarLoading manifest={manifest} />
      {homeOrder.map((display: any) => (
        // select loading based on the homeOrder of the manifest
        <div key={display}>
          {display === "slider" && <SliderLoading />}
          {display === "featureBar" && (
            <FeatureBarLoading manifest={manifest} />
          )}
          {display === "welcome" && <WelcomeLoading />}
          {display === "atAGlance" && <AtAGlanceLoading manifest={manifest} />}
          {display === "socialBar" && <SocialBarLoading manifest={manifest} />}
          {display === "label" && <LabelLoading manifest={manifest} />}
          {display === "possibilityBox" && (
            <PossibilityBoxLoading manifest={manifest} />
          )}
          {display === "newsDigest" && (
            <NewsDigestLoading manifest={manifest} />
          )}
          {display === "twoColumnButton" && (
            <TwoColumnButtonLoading manifest={manifest} />
          )}
          {display === "twoColumnView" && (
            <TwoColumnViewLoading manifest={manifest} />
          )}
          {display === "twoColumnVideo" && (
            <TwoColumnVideoLoading manifest={manifest} />
          )}
          {display === "socialMediaView" && (
            <SocialMediaLoading manifest={manifest} />
          )}
          {display === "contactCard" && (
            <ContactCardLoading manifest={manifest} />
          )}
          {display === "blogCard" && <BlogCardLoading manifest={manifest} />}
          {display === "blogCarousel" && (
            <BlogCarouselLoading manifest={manifest} />
          )}
          {display === "blogFAQ" && <BlogFAQLoading manifest={manifest} />}
          {display === "blogGridWidget" && (
            <BlogGridWidgetLoading manifest={manifest} />
          )}
          {display === "oCVvideoPlayer" && (
            <VideoPlayerLoading manifest={manifest} />
          )}
          {display === "monthlyFeature" && (
            <MonthlyFeatureLoading manifest={manifest} />
          )}
        </div>
      ))}
      <FooterLoading manifest={manifest} />
    </section>
  );
}
